import React from "react";
import Layout from "../../components/common/layout/layout";
import SEO from "../../components/common/layout/pkseo";
import Navigation from "../../components/common/navigation/navigation";
import MiniPOSForm from "../../components/sections/merchants/application";
import Header from "../../components/sections/merchants/header";
import Footer from "../../components/sections/footer";
import Inquiries from "../../components/sections/home/inquiries";
import WhyPOS from "../../components/sections/why-pos";
import MerchantBenefits from "../../components/sections/merchants/benefits";

const MiniPOS = () => {
  return (
    <Layout>
      <SEO title="OPay Pakistan Mini POS" />
      <Navigation background="white" />
      <Header />
      <MerchantBenefits />
      <MiniPOSForm />
      <WhyPOS />
      <Inquiries />
      <Footer />
    </Layout>
  );
};

export default MiniPOS;
